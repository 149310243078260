/** MUI */
import {
  Avatar,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {openLoginDialog} from "../../store/actions/loginDialogSlice";

type NavbarItemType = {
  name: string;
  isDisabled?: boolean;
};

const navItems: NavbarItemType[] = [
  {name: "Practice"},
  {name: "Modules", isDisabled: true},
  {name: "Test series", isDisabled: true},
];

export default function Navbar() {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const {isLoggedIn, data} = useSelector((state: RootState) => state.user);
  const isLoading = isLoggedIn === null;

  const handleLoginClick = () => {
    dispatch(openLoginDialog());
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid size={0.25} />
      <Grid size={8.5}>
        <Stack direction="row" spacing="20px" sx={{height: "56px"}}>
          <Paper
            onClick={() => {
              navigate("/");
            }}
            variant="feature"
            sx={{
              width: "60px",
              p: "12px",
              ":hover": {cursor: "pointer"},
            }}>
            <Avatar
              alt="Prep Genie"
              src={process.env.PUBLIC_URL + `/PrepGenieLogo.svg`}
              variant="square"
              sx={{
                width: "100%",
                height: "auto",
                m: "auto",
              }}
            />
          </Paper>
          <Paper
            variant="feature"
            sx={{
              width: "100%",
              p: "8px",
            }}>
            <List
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "fit-content",
                height: "100%",
                p: 0,
                gap: "8px",
              }}>
              {navItems.map((item, idx) => (
                <ListItemButton
                  key={idx}
                  selected={idx === 0}
                  sx={{
                    borderRadius: "12px",
                    "&.Mui-selected": {
                      bgcolor: "primary.main",
                      color: "primary.dark",
                      "&:hover": {
                        bgcolor: "primary.main",
                        color: "primary.dark",
                        cursor: "default",
                      },
                    },
                  }}
                  disabled={item.isDisabled}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2">{item.name}</Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Stack>
      </Grid>
      <Grid size={0.25} />
      <Grid size={2.75}>
        <Stack direction="row" sx={{height: "56px"}}>
          <Paper
            variant="feature"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              px: "8px",
            }}>
            <Stack justifyContent="center" alignItems={"center"} ml="12px">
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight={"1rem"}
                fontSize={"0.875rem"}>
                JEE Mains
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={"0.75rem"}
                style={{color: "secondary.light", fontWeight: 400}}>
                Class 11-12
              </Typography>
            </Stack>
            <Stack direction={"row"} marginLeft={"auto"}>
              {isLoading ? (
                <></>
              ) : isLoggedIn ? (
                <Avatar
                  alt={data?.profile?.firstName}
                  src={data?.profile?.profilePicture}
                  sx={{":hover": {cursor: "pointer"}}}
                />
              ) : (
                <>
                  <Button variant="text" onClick={handleLoginClick}>
                    Log in
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleLoginClick}>
                    Sign up
                  </Button>
                </>
              )}
            </Stack>
            {/* <GoogleLogin
              onSuccess={credentialResponse => {
                loginHandler(credentialResponse.credential);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
              shape="pill"
              size="large"
            /> */}
          </Paper>
        </Stack>
      </Grid>
      <Grid size={0.25} />
    </Grid>
  );
}
