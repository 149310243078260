import React from "react";
import {Outlet} from "react-router-dom";
import {ExamType} from "../../../config/enums";
import {examDetails} from "../../../config/exam";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store/store";
import {setExam} from "../../../store/actions/examSlice";
import Navbar from "../../../features/navbar";
import Box from "@mui/material/Box";

export default function ExamPage({examType}: {examType: ExamType}) {
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(setExam(examDetails[examType].type));

    return () => {
      dispatch(setExam(null));
    };
  }, [examType]);

  return (
    <Box
      sx={{
        backgroundColor: "primary.light",
        width: "100vw",
        minHeight: "100vh",
      }}>
      <Box height="16px" />
      <Navbar />
      <Box height="32px" />
      <Outlet />
      <Box height="32px" />
    </Box>
  );
}
