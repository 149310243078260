import {createSlice} from "@reduxjs/toolkit";

export enum LoginDialogSection {
  MAIN,
  MOBILE_OTP,
}

type LoginDialogStoreProps = {
  isOpen: boolean;
  section: LoginDialogSection;
};
const initialState: LoginDialogStoreProps = {
  isOpen: false,
  section: LoginDialogSection.MAIN,
};

export const loginDialogSlice = createSlice({
  name: "loginDialogGlobal",
  initialState,
  reducers: {
    openDialog: state => {
      if (state.isOpen === true) return;
      state.isOpen = true;
    },
    closeDialog: state => {
      if (state.isOpen === false) return;
      state.isOpen = false;
    },
    changeSection: (state, action: {payload: LoginDialogSection}) => {
      state.section = action.payload;
    },
  },
});

export const {
  openDialog: openLoginDialog,
  closeDialog: closeLoginDialog,
  changeSection: changeLoginDialogSection,
} = loginDialogSlice.actions;
export default loginDialogSlice.reducer;
