// Exam Enums
export enum ExamType {
  JEE_MAINS,
  CAT,
}

// Problem Enums
export enum ProblemFeatureType {
  Random = "is_random",
  MustDo = "is_must_do",
  Suggested = "is_suggested",
  Pyqs = "is_pyqs",
}

export enum ProblemTagsType {
  Default = "default",
  MustDo = "must-do",
  Suggested = "suggested",
  Pyqs = "pyqs",
}

export enum ProblemDifficultyType {
  Easy = "E",
  Medium = "M",
  Hard = "H",
}

export function stringToProblemDifficultyType(
  id: string,
): ProblemDifficultyType {
  switch (id) {
    case ProblemDifficultyType.Easy:
      return ProblemDifficultyType.Easy;
    case ProblemDifficultyType.Medium:
      return ProblemDifficultyType.Medium;
    case ProblemDifficultyType.Hard:
      return ProblemDifficultyType.Hard;
    default:
      return ProblemDifficultyType.Easy;
  }
}
export enum ProblemSubjectType {
  Physics = "OQXe",
  Chemistry = "nXEM",
  Maths = "GSff",
}

// Attempt Enums
export enum AttemptStatusType {
  Unseen = "U",
  Seen = "S",
  Correct = "C",
  Incorrect = "I",
}
export function stringToAttemptStatusType(id: string): AttemptStatusType {
  switch (id) {
    case AttemptStatusType.Unseen:
      return AttemptStatusType.Unseen;
    case AttemptStatusType.Seen:
      return AttemptStatusType.Seen;
    case AttemptStatusType.Correct:
      return AttemptStatusType.Correct;
    case AttemptStatusType.Incorrect:
      return AttemptStatusType.Incorrect;
    default:
      return AttemptStatusType.Unseen;
  }
}

// Problem Type
export enum ProblemType {
  SingleCorrect = "SC",
  Numerical = "SU",
  Unkown = "Unkown",
}
export function stringToProblemType(id: string): ProblemType {
  switch (id) {
    case ProblemType.SingleCorrect:
      return ProblemType.SingleCorrect;
    case ProblemType.Numerical:
      return ProblemType.Numerical;
    default:
      return ProblemType.Unkown;
  }
}
