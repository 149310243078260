import React, {forwardRef} from "react";

/** MUI */
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {TransitionProps} from "@mui/material/transitions";
import {Box, Fade} from "@mui/material";
import {MaterialSymbol} from "react-material-symbols";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {
  closeLoginDialog,
  LoginDialogSection,
} from "../../store/actions/loginDialogSlice";
import SectionMain from "./components/section-main";
import SectionMobileOtp from "./components/section-mobile-otp";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});
const DialogPaperProps = {
  sx: {
    maxHeight: "100vh",
    height: `min(80vh , 592px)`,
    maxWidth: "100vw",
    width: "min(80vw , 768px)",
    overflow: "visible",
    borderRadius: "16px",
  },
};

function SectionManager({section}: {section: LoginDialogSection}) {
  switch (section) {
    case LoginDialogSection.MOBILE_OTP:
      return <SectionMobileOtp />;
    case LoginDialogSection.MAIN:
      return <SectionMain />;
  }
}

export default function LoginDialog() {
  /** Dialog Store */
  const {isOpen: isDialogOpen, section} = useSelector(
    (state: RootState) => state.loginDialog,
  );
  const dispatch = useDispatch<AppDispatch>();

  /** Dialog Actions */
  const handleDialogClose = () => {
    if (isDialogOpen === false) return;
    dispatch(closeLoginDialog());
  };

  // Clear dialog content when dialog is closed
  const handleDialogCleanUp = () => {};

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => {}}
      aria-labelledby="problem-modal-title"
      TransitionComponent={Transition}
      TransitionProps={{
        onExited: () => {
          handleDialogCleanUp();
        },
      }}
      sx={{
        ".MuiDialogContent-root": {
          padding: 0,
          borderRadius: "16px",
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(28,39,48,.5)",
            backdropFilter: "blur(4px)",
          },
        },
      }}
      PaperProps={DialogPaperProps}>
      {/* Close Icon */}
      <Box
        onClick={handleDialogClose}
        sx={{
          position: "absolute",
          right: "-40px",
          top: "0px",
          ":hover": {
            cursor: "pointer",
            backgroundColor: "rgba(17, 23, 29, 0.8)",
          },
          transition:
            "background-color .1s linear,border-color .1s linear,color .1s linear",
          backgroundColor: "rgba(17, 23, 29, 0.6)",
          borderRadius: "50%",
          width: "32px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <MaterialSymbol icon="close" size={20} weight={400} color="white" />
      </Box>

      {/* Dialog Content */}
      <DialogContent
        sx={{
          px: 0,
          pb: 0,
          height: "fit-content",
          overflow: "hidden",
          display: "grid",
          gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
        }}>
        <SectionManager section={section} />
        <img
          style={{
            width: "100%",
            overflowClipMargin: "content-box",
            overflow: "clip",
          }}
          src="https://static.canva.com/authenticating/auth_dialog/auth_dialog_en.jpg"></img>
      </DialogContent>
    </Dialog>
  );
}
