import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setDeviceType} from "../../store/actions/deviceDetailsSlice";

export function useDeviceTypeUpdater() {
  const dispatch = useDispatch();

  useEffect(() => {
    function updateDeviceType() {
      const isMobile = window.matchMedia("(max-width: 768px)").matches;
      const isLaptop = window.matchMedia(
        "(min-width: 769px) and (max-width: 1599px)",
      ).matches;
      const isDesktop = window.matchMedia("(min-width: 1600px)").matches;

      dispatch(setDeviceType({isMobile, isLaptop, isDesktop}));
    }

    // Initial call
    updateDeviceType();

    // Add listener to handle resize
    window.addEventListener("resize", updateDeviceType);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, [dispatch]);
}
