import {createSlice} from "@reduxjs/toolkit";
import {ExamType} from "../../config/enums";

type ExamStoreProps = {
  exam: ExamType | null;
};
const initialState: ExamStoreProps = {
  exam: null,
};

export const examGlobalSlice = createSlice({
  name: "examGlobal",
  initialState,
  reducers: {
    setExam: (state, action: {payload: ExamStoreProps["exam"]}) => {
      state.exam = action.payload;
    },
  },
});

export const {setExam} = examGlobalSlice.actions;
export default examGlobalSlice.reducer;
