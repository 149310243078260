import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AttemptStatusType, ProblemType} from "../../../../config/enums";
import submitProblemAPI from "../../../../api/problem/submitProblem";
import {RootState} from "../../../../store/store";

/** PROBLEM DETAILS */
export type Options_ProblemDetailsType = {
  description: string;
  id: string;
}[];
export type ProblemDetailsType = {
  id: string;
  questionType: ProblemType;
  tags: {name: string}[];
  title: string;
  description: string;
  inputDetails?: Options_ProblemDetailsType;
};

/** USER ATTEMPT */
type SingleCorrectAttemptType = {
  inputValue?: string;
  correctValue?: string;
};
type NumericalAttemptType = {
  inputValue?: string;
  correctValue?: string;
  isError: boolean;
};
export type AttemptStateType = SingleCorrectAttemptType | NumericalAttemptType;

/** I. Pre Submit */
export type PreSubmit_ProblemUserAttemptType = {
  isSubmitted: false;
  attempt: AttemptStateType;
  status: AttemptStatusType; // Unseen, Seen
  time: number; // start time
};
/** II. Post Submit */
export type PostSubmit_ProblemUserAttemptType = {
  isSubmitted: true;
  attempt: AttemptStateType;
  time: number; // total time taken
  status: AttemptStatusType; // Incorrect, Correct
  solution: string;
};

export type ProblemUserAttemptType =
  | PreSubmit_ProblemUserAttemptType
  | PostSubmit_ProblemUserAttemptType;

/** STATE */
type StateType = {
  data?: {
    problemDetails: ProblemDetailsType;
    userAttempt: ProblemUserAttemptType;
  };
  isLoading: boolean;
  isAvailable: boolean;
  isUserLoggedIn: boolean;
};
const initialState: StateType = {
  data: undefined,
  isLoading: true,
  isAvailable: false,
  isUserLoggedIn: false,
};

export const problemDataSlice = createSlice({
  name: "problemContent",
  initialState,
  reducers: {
    clearProblemStore(state) {
      state.data = undefined;
      state.isLoading = true;
      state.isAvailable = false;
    },
    // set Problem Details and User Attempt to initial Pre-submit state
    setProblemContent(state, action: {payload: ProblemDetailsType}) {
      state.isLoading = false;
      state.isAvailable = true;
      const questionType = action.payload.questionType;

      const initialUserAttemptInputMap: Record<
        ProblemType,
        PreSubmit_ProblemUserAttemptType["attempt"]
      > = {
        [ProblemType.SingleCorrect]: {} as SingleCorrectAttemptType,
        [ProblemType.Numerical]: {inputValue: "", isError: false},
        [ProblemType.Unkown]: {} as AttemptStateType,
      };

      state.data = {
        problemDetails: action.payload,
        userAttempt: {
          isSubmitted: false,
          attempt: initialUserAttemptInputMap[questionType],
          status: AttemptStatusType.Unseen,
          time: 0,
        },
      };
    },

    setUserAttempt(state, action: {payload: ProblemUserAttemptType}) {
      state.isUserLoggedIn = true;
      if (state.data) state.data.userAttempt = action.payload;
    },

    /** Action Events */
    onOptionButtonClick(state, action: {payload: string}) {
      if (state.data?.userAttempt.isSubmitted) return;

      const clickedOptionId = action.payload;
      const selectedOptionId = state.data?.userAttempt.attempt.inputValue;

      var newSelectedOptionId: SingleCorrectAttemptType["inputValue"] =
        clickedOptionId;
      if (selectedOptionId && selectedOptionId === clickedOptionId)
        newSelectedOptionId = undefined;

      if (state.data)
        state.data.userAttempt.attempt.inputValue = newSelectedOptionId;
    },
    onNumericalInputChange(state, action: {payload: string}) {
      if (state.data)
        state.data.userAttempt.attempt.inputValue = action.payload;
    },
    onPreviousButtonClick(state) {},
    onNextButtonClick(state) {},
  },
});

export const onSubmitButtonClick = createAsyncThunk(
  "problemContent/onSubmitButtonClick",
  async (_, {getState, dispatch}) => {
    const state = getState() as RootState;
    const problemId = state.problemData.data?.problemDetails.id;
    const answer = state.problemData.data?.userAttempt.attempt.inputValue;
    if (problemId === undefined || answer === undefined) return;
    try {
      const res = await submitProblemAPI(problemId, answer);
      dispatch(setUserAttempt(res));
    } catch (error) {}
  },
);

export const {
  clearProblemStore,
  setProblemContent,
  onOptionButtonClick,
  onNumericalInputChange,
  onPreviousButtonClick,
  onNextButtonClick,
  setUserAttempt,
} = problemDataSlice.actions;
export default problemDataSlice.reducer;
