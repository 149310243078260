import {
  AttemptStatusType,
  ProblemFeatureType,
  ProblemSubjectType,
} from "../../config/enums";
import {SymbolCodepoints} from "react-material-symbols";

// Feature Icons
type FeatureIconsOptions = {
  [ProblemFeatureType.Random]: SymbolCodepoints;
  [ProblemFeatureType.MustDo]: SymbolCodepoints;
  [ProblemFeatureType.Suggested]: SymbolCodepoints;
  [ProblemFeatureType.Pyqs]: SymbolCodepoints;
};

export const featureIcons: FeatureIconsOptions = {
  [ProblemFeatureType.Random]: "auto_stories",
  [ProblemFeatureType.MustDo]: "autoplay",
  [ProblemFeatureType.Suggested]: "robot_2",
  [ProblemFeatureType.Pyqs]: "hourglass_bottom",
};

// Status Icons
type StatusIconsOptions = {
  [AttemptStatusType.Seen]: SymbolCodepoints;
  [AttemptStatusType.Unseen]: null;
  [AttemptStatusType.Correct]: SymbolCodepoints;
  [AttemptStatusType.Incorrect]: SymbolCodepoints;
};

export const statusIcons: StatusIconsOptions = {
  [AttemptStatusType.Unseen]: null,
  [AttemptStatusType.Seen]: "calendar_today",
  [AttemptStatusType.Correct]: "check_circle",
  [AttemptStatusType.Incorrect]: "close",
};

// Subject Icons
type SubjectIconsOptions = {
  [ProblemSubjectType.Chemistry]: SymbolCodepoints;
  [ProblemSubjectType.Physics]: SymbolCodepoints;
  [ProblemSubjectType.Maths]: SymbolCodepoints;
};

export const subjectIcons: SubjectIconsOptions = {
  [ProblemSubjectType.Chemistry]: "diversity_2",
  [ProblemSubjectType.Physics]: "wind_power",
  [ProblemSubjectType.Maths]: "calculate",
};
