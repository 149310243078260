import {Box, Button, OutlinedInput, Stack, Typography} from "@mui/material";
import React from "react";
import {MaterialSymbol} from "react-material-symbols";
import {useDispatch} from "react-redux";
import {useTimer} from "react-timer-hook";
import {AppDispatch} from "../../../store/store";
import {
  changeLoginDialogSection,
  LoginDialogSection,
} from "../../../store/actions/loginDialogSlice";

function formattedTime(time: number) {
  return time < 10 ? `0${time}` : time;
}

export default function SectionMobileOtp() {
  const [resendIsDisable, setResendIsDisable] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleNavigateBack = () => {
    dispatch(changeLoginDialogSection(LoginDialogSection.MAIN));
  };

  /** Resend OTP Countdown */
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 10);
  const {seconds, minutes, isRunning, restart} = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const onResendOtpClick = () => {
    // OTP API Call
    restart(expiryTimestamp);
  };

  return (
    <Stack p={"32px"} color={"#0d1216"} gap={"16px"}>
      <Stack gap={"16px"}>
        <Stack direction="row" alignItems="center">
          <Box
            onClick={handleNavigateBack}
            sx={{
              ":hover": {cursor: "pointer"},
              display: "flex",
              alignItems: "center",
            }}>
            <MaterialSymbol icon="arrow_back_ios" size={18} />
          </Box>
          <Typography
            variant="h3"
            align="left"
            sx={{fontSize: "1.5rem", fontWeight: 700}}>
            You’re almost signed up
          </Typography>
        </Stack>
        <Typography
          variant="subtitle1"
          align="left"
          sx={{
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
          }}>
          Enter the OTP we sent to +919625593712 to finish signing up.
        </Typography>
      </Stack>
      <Stack gap={"16px"}>
        <Stack gap={"8px"}>
          <Typography
            variant="h3"
            align="left"
            sx={{fontSize: "0.75rem", fontWeight: 600}}>
            One Time Password
          </Typography>
          <OutlinedInput
            id="otp"
            placeholder="Enter otp"
            slotProps={{
              root: {
                sx: {
                  borderRadius: "8px",
                  height: "40px",
                },
              },
              input: {sx: {fontSize: "0.875rem"}},
            }}
          />
        </Stack>
        <Button
          variant="contained"
          disableElevation
          sx={{
            borderRadius: "8px",
            height: "40px",
            backgroundColor: "primary.dark",
            color: "white",
            textTransform: "none",
          }}>
          Continue
        </Button>
        <Stack
          direction="row"
          gap={"4px"}
          alignItems={"center"}
          color="secondary.contrastText">
          <Typography
            variant="subtitle1"
            align="left"
            sx={{
              fontSize: "0.875rem",
              fontWeight: 400,
              lineHeight: "1.25rem",
            }}>
            Didn’t get the otp?
          </Typography>
          <Button
            variant="text"
            onClick={onResendOtpClick}
            disabled={isRunning}>
            <Typography
              variant="subtitle1"
              align="left"
              color={isRunning ? "secondary.contrastText" : "primary.dark"}
              sx={{
                fontSize: "0.875rem",
                fontWeight: 400,
                lineHeight: "1.25rem",
                borderBottom: isRunning ? "none" : "0.8px solid",
              }}>
              Resend OTP{" "}
              {isRunning && (
                <>
                  in {formattedTime(minutes)}:{formattedTime(seconds)}
                </>
              )}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
