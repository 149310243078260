import {ColorSystemOptions, createTheme} from "@mui/material/styles";

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    feature: true;
  }
}

const lightTheme: ColorSystemOptions = {
  palette: {
    primary: {
      light: "#F8FAFF",
      main: "#E7EDFF",
      dark: "#146AB5",
      contrastText: "#464255",
    },
    secondary: {
      light: "#F7F8F8",
      main: "#E6E6E6",
      dark: "#444559",
      contrastText: "#5A5A5A",
    },
    text: {
      primary: "#464255",
    },
  },
};

export const dropShadowTheme = "6px 6px 12px 0px #C7C7C740";
export const strokeTheme = "1px solid #D0D5DD";
export const fontFamilySecondaryTheme = '"Kalam", serif';

export const BORDER_RADIUS_INNER = "8px";
export const BORDER_RADIUS_OUTER = "12px";

const theme = createTheme({
  colorSchemes: {
    light: lightTheme,
    dark: false,
  },
  typography: {
    fontFamily: '"Hanken Grotesk", serif',
  },
  breakpoints: {
    values: {
      xs: 0, // > None
      sm: 760, // > Mobile
      md: 900, // > Tablets
      lg: 1280, // > Laptop
      xl: 1920, // > Desktop
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          boxShadow: dropShadowTheme,
        },
      },
      variants: [
        {
          props: {variant: "feature"},
          style: {
            backgroundColor: "white",
            borderRadius: BORDER_RADIUS_OUTER,
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: "none",
        },
      },
      variants: [
        {
          props: {variant: "contained"},
          style: ({theme}) => ({
            borderRadius: BORDER_RADIUS_INNER,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
          }),
        },
        {
          props: {variant: "text"},
          style: ({theme}) => ({
            borderRadius: BORDER_RADIUS_INNER,
            color: theme.palette.primary.dark,
          }),
        },
      ],
    },
  },
});

export default theme;
