import {createSlice} from "@reduxjs/toolkit";
import {ViewModelProps} from "../../../../config/type";
import {ProblemFeatureType} from "../../../../config/enums";
import {featureIcons} from "../../../../assets/icons/ProblemIcons";
import {featureTheme} from "../../../../config/themes/feature-theme";
import {GetFiltersAPIReturnType} from "../../../../api/problemset/get-filters";
import {SyllabusStructType} from "./SyllabusStructType";

export type FeaturesFilterType = {
  id: string;
  isPro?: boolean;
  viewModel: ViewModelProps;
};
export type SubjectFilterType = {
  id: string;
  viewModel: ViewModelProps;
};
export type ChapterFilterType = {
  id: string;
  questionsCount: number;
  topicsCount: number;
  viewModel: ViewModelProps;
};
export type TopicFilterType = {
  id: string;
  viewModel: ViewModelProps;
};
export type PatternFilterType = {
  id: string;
  viewModel: ViewModelProps;
};
export type DifficultyFilterType = {
  id: string;
  viewModel: ViewModelProps;
};
export type AttemptStatusFilterType = {
  id: string;
  viewModel: ViewModelProps;
};

export type StateType = {
  syllabus?: SyllabusStructType;
  data: {
    isLoading: boolean;
    isAvailable: boolean;
    subjects: SubjectFilterType[];
    chapters: Record<SubjectFilterType["id"], ChapterFilterType[]>;
    topics: Record<ChapterFilterType["id"], TopicFilterType[]>;
    patterns: PatternFilterType[];
    difficulty: DifficultyFilterType[];
    attemptStatus: AttemptStatusFilterType[];
    features: FeaturesFilterType[];
  };
  selected: {
    isAvailable: boolean;
    subjects: string[];
    chapters: string[];
    topics: string[];
    patterns: string[];
    difficulty: string[];
    attemptStatus: string[];
    features: string[];
    page: number;
  };
};
const initialState: StateType = {
  data: {
    isLoading: true,
    isAvailable: false,
    subjects: [],
    chapters: {},
    topics: {},
    patterns: [],
    difficulty: [],
    attemptStatus: [],
    features: [
      {
        id: ProblemFeatureType.Random,
        viewModel: {
          id: ProblemFeatureType.Random,
          name: "Workbook",
          description: "An exhaustive list of problems",
          icon: featureIcons[ProblemFeatureType.Random],
          color: featureTheme[ProblemFeatureType.Random],
        },
      },
      {
        id: ProblemFeatureType.MustDo,
        isPro: true,
        viewModel: {
          id: ProblemFeatureType.MustDo,
          name: "Foundations",
          altName: "Must Do",
          description: "Curated problem list which covers syllabus",
          icon: featureIcons[ProblemFeatureType.MustDo],
          color: featureTheme[ProblemFeatureType.MustDo],
        },
      },
      {
        id: ProblemFeatureType.Suggested,
        isPro: true,
        viewModel: {
          id: ProblemFeatureType.Suggested,
          name: "Adaptive Learning",
          altName: "Suggested",
          description: "AI recommended problems based on history",
          icon: featureIcons[ProblemFeatureType.Suggested],
          color: featureTheme[ProblemFeatureType.Suggested],
        },
      },
      {
        id: ProblemFeatureType.Pyqs,
        viewModel: {
          id: ProblemFeatureType.Pyqs,
          name: "PYQs",
          description: "Solve Past year questions in timed manner",
          icon: featureIcons[ProblemFeatureType.Pyqs],
          color: featureTheme[ProblemFeatureType.Pyqs],
        },
      },
    ],
  },
  selected: {
    isAvailable: false,
    subjects: [],
    chapters: [],
    topics: [],
    patterns: [],
    difficulty: [],
    attemptStatus: [],
    features: [ProblemFeatureType.MustDo],
    page: 0,
  },
};

export const tableFiltersSlice = createSlice({
  name: "tableFilters",
  initialState,
  reducers: {
    setFiltersViewModel(state, action: {payload: GetFiltersAPIReturnType}) {
      state.data = {...state.data, ...action.payload};
      state.selected.subjects = [action.payload.subjects[0].id];
      state.syllabus = action.payload.syllabus;
      state.selected.page = 1;
      state.data.isLoading = false;
      state.data.isAvailable = true;
    },
    setSelectedFilters(
      state,
      action: {
        payload: {
          isAvailable: boolean;
          subjects: string[];
          chapters: string[];
          topics: string[];
          patterns: string[];
          difficulty: string[];
          attemptStatus: string[];
          features: string[];
          page: number;
        };
      },
    ) {
      state.selected.chapters = action.payload.chapters;
      state.selected.subjects = action.payload.subjects;
      state.selected.topics = action.payload.topics;
      state.selected.attemptStatus = action.payload.attemptStatus;
      state.selected.difficulty = action.payload.difficulty;
      state.selected.patterns = action.payload.patterns;
      state.selected.features = action.payload.features;
      state.selected.page = action.payload.page;
      state.selected.isAvailable = true;
    },
    clearSelectedFilters(state) {
      state.selected.attemptStatus = [];
      state.selected.difficulty = [];
      state.selected.patterns = [];
    },
    setSelectedFeatureFilter(state, action: {payload: string}) {
      state.selected.features = [action.payload];
    },
    setSelectedSubjectFilter(state, action: {payload: string}) {
      state.selected.subjects = [action.payload];
    },
    toggleDifficultyFilter(state, action: {payload: string}) {
      const id = action.payload;
      if (state.selected.difficulty.includes(id)) {
        state.selected.difficulty = state.selected.difficulty.filter(
          item => item !== id,
        );
      } else {
        state.selected.difficulty = [...state.selected.difficulty, id];
      }
    },
    togglePatternFilter(state, action: {payload: string}) {
      const id = action.payload;
      if (state.selected.patterns.includes(id)) {
        state.selected.patterns = state.selected.patterns.filter(
          item => item !== id,
        );
      } else {
        state.selected.patterns = [...state.selected.patterns, id];
      }
    },
    toggleStatusFilter(state, action: {payload: string}) {
      const id = action.payload;
      if (state.selected.attemptStatus.includes(id)) {
        state.selected.attemptStatus = state.selected.attemptStatus.filter(
          item => item !== id,
        );
      } else {
        state.selected.attemptStatus = [...state.selected.attemptStatus, id];
      }
    },
    setSyllabus(
      state,
      action: {payload: {subjectId: string; chapterId: string}},
    ) {
      const {subjectId, chapterId} = action.payload;
      if (state.syllabus) {
        const prevSelectedSubjectId = state.syllabus.selectedSubjects[0];
        state.syllabus.subjectsMap[prevSelectedSubjectId].isSelected = false;

        state.syllabus.selectedSubjects = [subjectId];
        state.syllabus.subjectsMap[subjectId].isSelected = true;

        state.syllabus.subjectsMap[subjectId].selectedChapters = [chapterId];
      }
    },
    // Action Events
    onTableNextButtonClick(state) {
      state.selected.page += 1;
    },
    onTablePreviousButtonClick(state) {
      state.selected.page -= 1;
    },
    onChapterSelectionChange(state, action: {payload: string}) {
      const curSelected = state.selected.chapters;
      const id = action.payload;
      if (curSelected.includes(id)) {
        state.selected.chapters = curSelected.filter(item => item !== id);
      } else {
        state.selected.chapters = [...curSelected, id];
      }
    },
  },
});

export const {
  setFiltersViewModel,
  setSelectedFilters,
  clearSelectedFilters,
  setSelectedFeatureFilter,
  setSelectedSubjectFilter,
  toggleDifficultyFilter,
  togglePatternFilter,
  toggleStatusFilter,
  setSyllabus,
  onTableNextButtonClick,
  onTablePreviousButtonClick,
  onChapterSelectionChange,
} = tableFiltersSlice.actions;
export default tableFiltersSlice.reducer;
