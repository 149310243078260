import {createSlice} from "@reduxjs/toolkit";
import {ExamType} from "../../config/enums";

export type UserDataType = {
  profile: {
    firstName: string;
    lastName: string;
    email: string;
    profilePicture: string;
  } | null;
  selectedExam: ExamType | null;
};
type UserStoreProps = {
  data: UserDataType | null;
  /**
   * null -> Not checked
   * true -> Logged In
   * false -> Logged Out
   */
  isLoggedIn: boolean | null;
};
const initialState: UserStoreProps = {
  data: null,
  isLoggedIn: null,
};

export const usersSlice = createSlice({
  name: "userGlobal",
  initialState,
  reducers: {
    setLoggedIn: state => {
      state.isLoggedIn = true;
    },
    setLoggedOut: state => {
      state.isLoggedIn = false;
      state.data = null;
    },
    setProfile: (
      state,
      action: {
        payload: UserStoreProps["data"];
      },
    ) => {
      state.isLoggedIn = true;
      state.data = action.payload;
    },
  },
});

export const {setLoggedIn, setLoggedOut, setProfile} = usersSlice.actions;
export default usersSlice.reducer;
