import {configureStore} from "@reduxjs/toolkit";
import examSliceReducer from "./actions/examSlice";
import userSliceReducer from "./actions/userSlice";
import loginDialogSlice from "./actions/loginDialogSlice";
import problemDataSliceReducer from "../features/problem-dialog/store/actions/problemContentSlice";
import tableDataSliceReducer from "../features/problemset-table/store/actions/tableDataSlice";
import tableFiltersSliceReducer from "../features/problemset-table/store/actions/tableFiltersSlice";
import deviceDetailsSliceReducer from "./actions/deviceDetailsSlice";

export const store = configureStore({
  reducer: {
    examSession: examSliceReducer,
    user: userSliceReducer,
    loginDialog: loginDialogSlice,
    problemData: problemDataSliceReducer,
    tableData: tableDataSliceReducer,
    filters: tableFiltersSliceReducer,
    deviceDetails: deviceDetailsSliceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
