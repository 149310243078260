import {instanceWithHeaders as axios} from "../../config/axios";
import {AttemptStatusType, stringToAttemptStatusType} from "../../config/enums";
import {ProblemUserAttemptType} from "../../features/problem-dialog/store/actions/problemContentSlice";

type ResponseType = {
  status: string;
  time_taken: number;
  post_submit: {
    selected_answer: string;
    correct_answer: string;
    solution: string;
  };
};

type ReturnType = ProblemUserAttemptType;
function convertResultToData(res: ResponseType): ReturnType {
  return {
    isSubmitted: true,
    time: res.time_taken,
    status: stringToAttemptStatusType(res.status),
    attempt: {
      inputValue: res.post_submit.selected_answer,
      correctValue: res.post_submit.correct_answer,
    },
    solution: res.post_submit.solution,
  };
}

export default async function submitProblemAPI(
  questionId: string,
  answer: string,
) {
  const data = {
    answer: answer,
  };
  return await axios
    .post(`/core/questions/${questionId}/submit`, data)
    .then(res => {
      return convertResultToData(res.data);
    })
    .catch(err => {
      throw err;
    });
}
