import axios from "../../config/axios";

type ResponseType = {
  email?: string;
  first_name?: string;
  profile_picture?: string;
};

type ReturnType = {};
function convertResultToData(result: ResponseType): ReturnType {
  return {};
}

export default async function googleLoginAPI(accessToken: string) {
  const data = {
    access_token: accessToken,
  };
  return await axios
    .post(`/users/login/`, data)
    .then(res => {
      return convertResultToData(res.data);
    })
    .catch(err => {
      throw err;
    });
}
