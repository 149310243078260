import googleLoginAPI from "../../../api/user/google-login";
import {closeLoginDialog} from "../../../store/actions/loginDialogSlice";
import {setLoggedIn} from "../../../store/actions/userSlice";
import {store} from "../../../store/store";

export default async function googleLoginHandler(access_token?: string) {
  if (access_token) {
    try {
      const res = await googleLoginAPI(access_token);
      store.dispatch(setLoggedIn());
      store.dispatch(closeLoginDialog());
    } catch (e) {
      console.log(e);
      // throw e;
    }
  } else {
    // throw "Login Error, please try again";
  }
}
