import axios from "../../config/axios";
import {getExamTypeFromId} from "../../config/exam";
import {UserDataType} from "../../store/actions/userSlice";

type ResponseType = {
  email?: string;
  first_name?: string;
  last_name?: string;
  profile_picture?: string;
  exam_selected?: string;
};

type ReturnType = UserDataType;
function convertResultToData(result: ResponseType): ReturnType {
  return {
    profile: {
      email: result.email || "",
      firstName: result.first_name || "Chipkali",
      lastName: result.last_name || "",
      profilePicture:
        result.profile_picture ||
        "https://t4.ftcdn.net/jpg/02/41/73/77/240_F_241737743_GqoWwNjNgQ9SyssFneQg6QjRsehU9VeF.jpg",
    },
    selectedExam: result.exam_selected
      ? getExamTypeFromId(result.exam_selected)
      : null,
  };
}

export default async function getProfileAPI() {
  return await axios
    .get(`/users/profile/`)
    .then(res => {
      return convertResultToData(res.data);
    })
    .catch(err => {
      throw err;
    });
}

export async function getProfileHandler() {
  try {
    return await getProfileAPI();
  } catch (e) {
    // throw e;
    return null;
  }
}
