import {createSlice} from "@reduxjs/toolkit";

type DeviceDetailsStoreProps = {
  isMobile: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
};
const initialState: DeviceDetailsStoreProps = {
  isMobile: false,
  isLaptop: false,
  isDesktop: false,
};

export const deviceDetailsSliceReducer = createSlice({
  name: "examGlobal",
  initialState,
  reducers: {
    setDeviceType: (
      state,
      action: {
        payload: {isMobile: boolean; isLaptop: boolean; isDesktop: boolean};
      },
    ) => {
      return {...state, ...action.payload};
    },
  },
});

export const {setDeviceType} = deviceDetailsSliceReducer.actions;
export default deviceDetailsSliceReducer.reducer;
