import {Box, Button, OutlinedInput, Stack, Typography} from "@mui/material";
import GoogleIcon from "../../../assets/icons/custom/google_icon";
import {useGoogleLogin} from "@react-oauth/google";
import {AppDispatch} from "../../../store/store";
import {useDispatch} from "react-redux";
import {
  changeLoginDialogSection,
  LoginDialogSection,
} from "../../../store/actions/loginDialogSlice";
import googleLoginHandler from "../handlers/googleLoginHandler";

export default function SectionMain() {
  const dispatch = useDispatch<AppDispatch>();

  /** Google Login */
  const googleLoginButtonClick = useGoogleLogin({
    onSuccess: codeResponse => {
      googleLoginHandler(codeResponse.access_token);
    },
    onError: error => console.log("Login Failed:", error),
  });

  /** Mobile Login */
  const handleSendMobileOtp = () => {
    // OTP API Call
    dispatch(changeLoginDialogSection(LoginDialogSection.MOBILE_OTP));
  };

  return (
    <Stack p={"32px"} color={"#0d1216"} gap={"16px"}>
      <Stack gap={"16px"}>
        <Typography
          variant="h3"
          align="left"
          sx={{fontSize: "1.5rem", fontWeight: 700}}>
          Welcome champ 👋
        </Typography>
        <Typography
          variant="subtitle1"
          align="left"
          sx={{
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
          }}>
          Solve smarter, not harder! Your next question is just a login away.
        </Typography>
      </Stack>
      <Stack gap={"16px"}>
        <Stack gap={"8px"}>
          <Typography
            variant="h3"
            align="left"
            sx={{fontSize: "0.75rem", fontWeight: 600}}>
            Mobile number
          </Typography>
          <OutlinedInput
            id="mobile"
            placeholder="E.g., 9812345678"
            slotProps={{
              root: {
                sx: {
                  borderRadius: "8px",
                  height: "40px",
                },
              },
              input: {sx: {fontSize: "0.875rem"}},
            }}
            // endAdornment
          />
        </Stack>
        <Button
          variant="contained"
          disableElevation
          sx={{
            borderRadius: "8px",
            height: "40px",
            backgroundColor: "primary.dark",
            color: "white",
            textTransform: "none",
          }}
          onClick={handleSendMobileOtp}>
          Send One Time Password
        </Button>
      </Stack>
      <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
        <Box
          height={"1px"}
          borderBottom={"1px solid"}
          borderColor={"secondary.dark"}
          width={"100%"}
        />
        <Typography
          variant="subtitle1"
          align="left"
          sx={{
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
            color: "secondary.dark",
            px: 1,
          }}>
          or
        </Typography>
        <Box
          height={"1px"}
          borderBottom={"1px solid"}
          borderColor={"secondary.dark"}
          width={"100%"}
        />
      </Stack>
      <Button
        onClick={() => googleLoginButtonClick()}
        variant="outlined"
        disableElevation
        startIcon={<GoogleIcon />}
        sx={{
          borderRadius: "8px",
          borderColor: "secondary.dark",
          height: "40px",
          color: "inherit",
          textTransform: "none",
          fontSize: "0.875rem",
        }}>
        Continue with Google
      </Button>
    </Stack>
  );
}
