import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {HelmetProvider} from "react-helmet-async";
// import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";

/** MATERIAL UI */
import {ThemeProvider} from "@mui/material/styles";
import theme from "./config/themes/theme";
import "react-material-symbols/rounded";

/** React Slick */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/** Google Login */
import {GoogleOAuthProvider} from "@react-oauth/google";

/** Redux */
import {Provider} from "react-redux";
import {store} from "./store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <HelmetProvider>
      <GoogleOAuthProvider clientId="814016930441-r0407nnnvnamvi3cj7o4lpo8ttvdi1lb.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <App />
          </Provider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </HelmetProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
