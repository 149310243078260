import React, {Suspense, lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {examDetails} from "./config/exam";
import {ExamType} from "./config/enums";
import NotFound from "./pages/not-found/NotFound";
import ExamPage from "./pages/exam/common/ExamPage";
import LoginDialog from "./features/login-dialog";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "./store/store";
import {getProfileHandler} from "./api/user/get-profile";
import {setLoggedOut, setProfile} from "./store/actions/userSlice";
import {useDeviceTypeUpdater} from "./utils/device-details/update-device-type";

/** Pages */
const LandingPage = lazy(() => import("./pages/landing/LandingPage"));
const Problemset = lazy(
  () => import("./pages/exam/common/section/problemset/Problemset"),
);

function App() {
  const exams = (
    Object.values(ExamType).filter(x => typeof x === "number") as ExamType[]
  ).map(key => examDetails[key]);
  useDeviceTypeUpdater();

  const {isLoggedIn, data} = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    async function getProfile() {
      if (data !== null) {
        // do Nothing
      } else if (isLoggedIn === false) {
        // do Nothing
      } else {
        const res = await getProfileHandler();
        if (res === null) {
          dispatch(setLoggedOut());
        } else {
          dispatch(setProfile(res));
        }
      }
    }
    getProfile();
  }, [isLoggedIn]);

  return (
    <div>
      <Suspense fallback={<>Loading...</>}>
        <LoginDialog />
        <Routes>
          <Route index element={<LandingPage />} />

          {exams.map(exam => (
            <Route
              key={exam.type}
              path={exam.route}
              element={<ExamPage examType={exam.type} />}>
              <Route index element={<Problemset examType={exam.type} />} />
              <Route
                path="solve/:problemId"
                element={<Problemset showProblemDialog examType={exam.type} />}
              />
              <Route path="planner" element={<NotFound />} />

              <Route path="*" element={<Navigate to={"."} />} />
            </Route>
          ))}

          {/* Fallback route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
