import {Box, Button, Stack, Typography} from "@mui/material";

export default function NotFound() {
  return (
    <Box width={"100vw"} height={"100vh"}>
      <Stack
        spacing={1}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10%",
          transform: "translate(0%, -50%)",
        }}>
        <Typography variant="h2" fontWeight={700}>
          404
        </Typography>
        <Typography variant="h4" fontWeight={600}>
          Ooops! <br />
          Page Not Found
        </Typography>
        <Typography
          variant="h6"
          fontWeight={500}
          py={2}
          sx={{color: "secondary.dark"}}>
          This page doesn&apos;t exist or was removed!
          <br />
          We suggest you to go back home
        </Typography>
        <Button
          variant="outlined"
          sx={{
            color: "primary.contrastText",
            borderColor: "primary.contrastText",
            borderRadius: "2rem",
            py: 1.5,
          }}
          size="large"
          href="/">
          Back to Home
        </Button>
      </Stack>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: "5%",
          transform: "translate(0%, -50%)",
        }}>
        <img
          src="/not-found.png"
          style={{
            height: "70vh",
          }}
        />
      </Box>
    </Box>
  );
}
