import {SimplePaletteColorOptions} from "@mui/material/styles";
import {ProblemFeatureType} from "../enums";

type FeatureColorOptions = {
  [ProblemFeatureType.Random]: SimplePaletteColorOptions;
  [ProblemFeatureType.MustDo]: SimplePaletteColorOptions;
  [ProblemFeatureType.Suggested]: SimplePaletteColorOptions;
  [ProblemFeatureType.Pyqs]: SimplePaletteColorOptions;
};

export const featureTheme: FeatureColorOptions = {
  [ProblemFeatureType.Random]: {
    light: "#E7EDFF80",
    main: "#4471FF",
  },
  [ProblemFeatureType.MustDo]: {
    light: "#FEF7E0",
    main: "#F29900",
  },
  [ProblemFeatureType.Suggested]: {
    light: "#D7F8EB",
    main: "#0FC87D",
  },
  [ProblemFeatureType.Pyqs]: {
    light: "#FFE0EB80",
    main: "#FF82AC",
  },
};
