import {createSlice} from "@reduxjs/toolkit";
import {ViewModelProps} from "../../../../config/type";
import {ProblemFeatureType} from "../../../../config/enums";
import {featureIcons} from "../../../../assets/icons/ProblemIcons";
import {featureTheme} from "../../../../config/themes/feature-theme";
import {GetFiltersAPIReturnType} from "../../../../api/problemset/get-filters";

export type FeaturesFilterType = {
  id: string;
  isPro?: boolean;
  viewModel: ViewModelProps;
};
export type SubjectFilterType = {
  id: string;
  viewModel: ViewModelProps;
};
export type ChapterFilterType = {
  id: string;
  questionsCount: number;
  topicsCount: number;
  viewModel: ViewModelProps;
};
export type TopicFilterType = {
  id: string;
  viewModel: ViewModelProps;
};
export type PatternFilterType = {
  id: string;
  viewModel: ViewModelProps;
};
export type DifficultyFilterType = {
  id: string;
  viewModel: ViewModelProps;
};
export type AttemptStatusFilterType = {
  id: string;
  viewModel: ViewModelProps;
};

export type StateType = {
  data: {
    subjects: SubjectFilterType[];
    chapters: Record<SubjectFilterType["id"], ChapterFilterType[]>;
    topics: Record<ChapterFilterType["id"], TopicFilterType[]>;
    patterns: PatternFilterType[];
    difficulty: DifficultyFilterType[];
    attemptStatus: AttemptStatusFilterType[];
    features: FeaturesFilterType[];
  };
  selected: {
    subjects: string[];
    chapters: string[];
    topics: string[];
    patterns: string[];
    difficulty: string[];
    attemptStatus: string[];
    features: string[];
    page: number;
  };
  isLoading: boolean;
  isAvailable: boolean;
};
const initialState: StateType = {
  data: {
    subjects: [],
    chapters: {},
    topics: {},
    patterns: [],
    difficulty: [],
    attemptStatus: [],
    features: [
      {
        id: ProblemFeatureType.Random,
        viewModel: {
          id: ProblemFeatureType.Random,
          name: "Random",
          description: "Shuffled problem list",
          icon: featureIcons[ProblemFeatureType.Random],
          color: featureTheme[ProblemFeatureType.Random],
        },
      },
      {
        id: ProblemFeatureType.MustDo,
        isPro: true,
        viewModel: {
          id: ProblemFeatureType.MustDo,
          name: "Must Do",
          description: "Curated problem list which covers syllabus",
          icon: featureIcons[ProblemFeatureType.MustDo],
          color: featureTheme[ProblemFeatureType.MustDo],
        },
      },
      {
        id: ProblemFeatureType.Suggested,
        isPro: true,
        viewModel: {
          id: ProblemFeatureType.Suggested,
          name: "Suggested",
          description: "AI recommended problems based on history",
          icon: featureIcons[ProblemFeatureType.Suggested],
          color: featureTheme[ProblemFeatureType.Suggested],
        },
      },
      {
        id: ProblemFeatureType.Pyqs,
        viewModel: {
          id: ProblemFeatureType.Pyqs,
          name: "PYQs",
          description: "Solve Past year questions in timed manner",
          icon: featureIcons[ProblemFeatureType.Pyqs],
          color: featureTheme[ProblemFeatureType.Pyqs],
        },
      },
    ],
  },
  selected: {
    subjects: [],
    chapters: [],
    topics: [],
    patterns: [],
    difficulty: [],
    attemptStatus: [],
    features: [ProblemFeatureType.Random],
    page: 0,
  },
  isLoading: false,
  isAvailable: false,
};

export const tableFiltersSlice = createSlice({
  name: "tableFilters",
  initialState,
  reducers: {
    setFiltersViewModel(state, action: {payload: GetFiltersAPIReturnType}) {
      state.isLoading = false;
      state.data = {...state.data, ...action.payload};
      state.selected.subjects = [action.payload.subjects[0].id];
      state.selected.page = 1;
      state.isAvailable = true;
    },
    clearFilters(state) {
      state.selected.attemptStatus = [];
      state.selected.difficulty = [];
      state.selected.patterns = [];
    },
    setSelectedFeatureFilter(state, action: {payload: string}) {
      state.selected.features = [action.payload];
    },
    setSelectedSubjectFilter(state, action: {payload: string}) {
      state.selected.subjects = [action.payload];
    },
    toggleDifficultyFilter(state, action: {payload: string}) {
      const id = action.payload;
      if (state.selected.difficulty.includes(id)) {
        state.selected.difficulty = state.selected.difficulty.filter(
          item => item !== id,
        );
      } else {
        state.selected.difficulty = [...state.selected.difficulty, id];
      }
    },
    togglePatternFilter(state, action: {payload: string}) {
      const id = action.payload;
      if (state.selected.patterns.includes(id)) {
        state.selected.patterns = state.selected.patterns.filter(
          item => item !== id,
        );
      } else {
        state.selected.patterns = [...state.selected.patterns, id];
      }
    },
    toggleStatusFilter(state, action: {payload: string}) {
      const id = action.payload;
      if (state.selected.attemptStatus.includes(id)) {
        state.selected.attemptStatus = state.selected.attemptStatus.filter(
          item => item !== id,
        );
      } else {
        state.selected.attemptStatus = [...state.selected.attemptStatus, id];
      }
    },
    // Action Events
    onTableNextButtonClick(state) {
      state.selected.page += 1;
    },
    onTablePreviousButtonClick(state) {
      state.selected.page -= 1;
    },
    onChapterSelectionChange(state, action: {payload: string}) {
      const curSelected = state.selected.chapters;
      const id = action.payload;
      if (curSelected.includes(id)) {
        console.log("removing", id);
        state.selected.chapters = curSelected.filter(item => item !== id);
      } else {
        console.log("adding", id);
        state.selected.chapters = [...curSelected, id];
      }
    },
  },
});

export const {
  setFiltersViewModel,
  clearFilters,
  setSelectedFeatureFilter,
  setSelectedSubjectFilter,
  toggleDifficultyFilter,
  togglePatternFilter,
  toggleStatusFilter,
  onTableNextButtonClick,
  onTablePreviousButtonClick,
  onChapterSelectionChange,
} = tableFiltersSlice.actions;
export default tableFiltersSlice.reducer;
