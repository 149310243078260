import axios from "axios";
import {store} from "../store/store";
import {examDetails} from "./exam";

const prodURL = process.env.REACT_APP_PRODUCTION_URL;
const testURL = process.env.REACT_APP_STAGING_URL;

const baseURL = true ? prodURL : testURL;

// INSTANCE WITH HEADER
const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

instance.interceptors.request.use(
  config => {
    let examSessionType = store.getState().examSession.exam;

    if (examSessionType !== null) {
      config.headers["X-Exam-Name"] = examDetails[examSessionType].header;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      let status = error.response.status;
      let message = error.response.data;
      if (status === 401 || status === 404) return Promise.reject(); // do not show snackbar to user
      if (Math.floor(status / 100) === 4)
        return Promise.reject({message: message, status: status});
      if (Math.floor(status / 100) === 5)
        return Promise.reject("Unexpected error occured");
    }
    return Promise.reject(error.message);
  },
);

// INSTANCE WITHOUT HEADER
const instanceWithoutHeaders = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

instanceWithoutHeaders.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      let status = error.response.status;
      let message = error.response.data;
      if (status / 100 === 4)
        return Promise.reject({message: message, status: status});
      if (status / 100 === 5) return Promise.reject("Unexpected error occured");
    }
    return Promise.reject(error.message);
  },
);

export {
  instance as instanceWithHeaders,
  instanceWithoutHeaders as default,
  baseURL,
};
