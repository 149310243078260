import {createSlice} from "@reduxjs/toolkit";
import {AttemptStatusType, ProblemTagsType} from "../../../../config/enums";

export type ProblemsetRowType = {
  id: string;
  title: string;
  tags: {id: string; type: ProblemTagsType; name: string}[];
  patternId: string;
  difficultyId: string;
  attempt: {
    status: AttemptStatusType;
    timeTaken: string;
  } | null;
};
export type ProblemsetData = {
  list: ProblemsetRowType[];
  count: number;
  next: string | null;
  previous: string | null;
};
type StateType = {
  data: ProblemsetData;
  isLoading: boolean;
  isAvailable: boolean;
};
const initialState: StateType = {
  data: {
    list: [],
    count: 0,
    next: null,
    previous: null,
  },
  isLoading: true,
  isAvailable: false,
};

export const tableDataSlice = createSlice({
  name: "tableData",
  initialState,
  reducers: {
    setLoadingTrue(state) {
      state.isLoading = true;
    },
    setProblemsetListData(state, action: {payload: ProblemsetData}) {
      state.isLoading = false;
      state.isAvailable = true;
      state.data = action.payload;
    },
  },
});

export const {
  setLoadingTrue: setProblemListLoadingTrue,
  setProblemsetListData,
} = tableDataSlice.actions;
export default tableDataSlice.reducer;
